class UWScroll {
  static ReferPositionOption :ReferPositionOption = {
    activeClass: 'actived',
    sedationLine: 0,
    once: false,
    direction: 'both',
  }
  static ReferElementOption :ReferElementOption = {
    rootMargin: '0px',
    timing: 0,
    activeClass: 'actived',
    once: false,
    direction: 'both',
  }

  static applyPositionY(action :Function) :void {
    document.addEventListener('scroll', () => {
      const result = {
        upper: window.scrollY,
        lower: window.scrollY - document.body.clientHeight + document.documentElement.clientHeight,
      }
      action(result);
    });
  }

  static setScrollActionPositionBase(
    element :HTMLElement,
    ignitionLine :number,
    sedationLine :number,
    activeClass :string,
    once :boolean = false,
    direction :'up' | 'down' | 'both',
    actAction :Function = (elm :HTMLElement) => {},
    deactAction :Function = (elm :HTMLElement) => {}
  ) :void {
    let posY = 0;
    let done = false;
    document.addEventListener('scroll', () => {
      const startPosY = (ignitionLine < 0) ? document.body.scrollHeight + ignitionLine : ignitionLine;
      const endPosY = (sedationLine <= 0) ? document.body.scrollHeight - document.documentElement.clientHeight + sedationLine : sedationLine;
  
      let isDown = (posY < window.pageYOffset);
      posY = Math.floor(window.pageYOffset)

      const directionResult = (isDown && direction === 'down') || (!isDown && direction === 'up') || (direction === 'both');
      const rangeResult = (startPosY <= posY) && (posY <= endPosY);
      const onceResult = (once && !done) || !once;

      if(directionResult && rangeResult && onceResult){
        done = true;
        actAction(element);
        if(activeClass.length !== 0 && !element.classList.contains(activeClass)){
          element.classList.add(activeClass)
        }
      }else if(!once){
        deactAction(element);
        if(activeClass.length !== 0 && element.classList.contains(activeClass)){
          element.classList.remove(activeClass)
        }
      }
    });
  }

  static setScrollActionElementBase(
    activateElement :HTMLElement,
    referElement: HTMLElement,
    rootMargin: string,
    timing: number | Array<number>,
    activeClass :string,
    once :boolean = false,
    direction :'up' | 'down' | 'both',
    actAction :Function = (elm :HTMLElement) => {},
    deactAction :Function = (elm :HTMLElement) => {}
  ) :void {
    let done = false;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((ent) => {
        const isDown = (ent.isIntersecting === (0 < ent.boundingClientRect.top));
        const directionResult = 
          direction === 'both'
          || (isDown && direction === 'down')
          || (!isDown && direction === 'up');
        const intersectResult = ent.isIntersecting;
        const onceResult = (once && !done) || !once;

        if(directionResult && intersectResult && onceResult){
          done = true;
          actAction(activateElement);
          if(activeClass.length !== 0 && !activateElement.classList.contains(activeClass)){
            activateElement.classList.add(activeClass);
          }
        }else if(!once){
          deactAction(activateElement);
          if(activeClass.length !== 0 && activateElement.classList.contains(activeClass)){
            activateElement.classList.remove(activeClass);
          }
        }
      });
    },{
      root: null,
      rootMargin: rootMargin,
      threshold: timing,
    });

    observer.observe(referElement);
  }

  static setScrollLock (
    targetElement :HTMLElement,
    activateClass :string
  ) :void {
    const wheelEventType = 'onmousewheel' in document ? 'mousewheel' : 'wheel';
    const keydownEventType = 'keydown';
    const touchEventType = 'touchmove'
    const arrows = ['ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft']
    let lastTouchEvent :TouchEvent|undefined

    const IsInsideArea = (e :Event) => {
      if(e.type === 'keydown'){
        return true
      }
      return e.composedPath().includes(targetElement)
    }

    interface Point {
      x: 'left'|'right'|undefined;
      y: 'up'|'down'|undefined
    }

    const getDirection = (e :Event) => {
      const result :Point = {
        x: void 0,
        y: void 0,
      }

      if(e.type === 'keydown') {
        switch((e as KeyboardEvent).key){
          case 'ArrowDown':
            result.y = 'down'
            break
          case 'ArrowUp':
            result.y = 'up'
            break
          case 'ArrowLeft':
            result.x = 'left'
            break
          case 'ArrowRight':
            result.x = 'right'
            break
        }
        return result
      }else if(e.type === 'touchmove') {
        if(lastTouchEvent === void 0){
          lastTouchEvent = e as TouchEvent
          return
        }

        const diffX = (e as TouchEvent).touches[0].pageX - lastTouchEvent.touches[0].pageX
        const diffY = (e as TouchEvent).touches[0].pageY - lastTouchEvent.touches[0].pageY
        result.x = 0 < diffX ? 'left' : 'right'
        result.y = 0 < diffY ? 'up' : 'down'
        lastTouchEvent = e as TouchEvent
        return result
      }
      
      result.x = 0 < (e as WheelEvent).deltaX ? 'right' : 'left'
      result.y = 0 < (e as WheelEvent).deltaY ? 'down' : 'up'
      return result
    }

    const lockScroll = (e :Event) => {
      const otherKeys = e.type === 'keydownEvent' && !arrows.includes((e as KeyboardEvent).key)
      if(otherKeys){
        return
      }

      if(targetElement.clientHeight === targetElement.scrollHeight){
        e.preventDefault()
        return
      }

      if(!IsInsideArea(e)){
        e.preventDefault()
        return
      }

      const direction :Point|undefined = getDirection(e)
      if(direction === void 0 ){
        return
      }

      if(targetElement.scrollTop === 0 && direction.y === 'up'){
        e.preventDefault()
        return
      }
      if(targetElement.scrollTop + targetElement.getBoundingClientRect().height === targetElement.scrollHeight && direction.y === 'down'){
        e.preventDefault()
        return
      }
      //todo 先々はx方向も対応する
    }

    const observer = new MutationObserver((rcds :Array<MutationRecord>) => {
      rcds.forEach((rcd :MutationRecord) => {
        if(rcd.oldValue && !rcd.oldValue.split(' ').includes(activateClass) && targetElement.classList.contains(activateClass)){
          //activate
          document.addEventListener(wheelEventType, lockScroll , { passive: false })
          document.addEventListener(keydownEventType, lockScroll, { passive: false })
          document.addEventListener(touchEventType, lockScroll, { passive: false })
        }else if(rcd.oldValue && rcd.oldValue.split(' ').includes(activateClass) && !targetElement.classList.contains(activateClass)){
          //deactivate
          document.removeEventListener(wheelEventType, lockScroll)
          document.removeEventListener(keydownEventType, lockScroll)
          document.removeEventListener(touchEventType, lockScroll)
        }
      })
    })
    observer.observe(targetElement, {
      attributes: true,
      attributeFilter: ['class'],
      attributeOldValue: true,
    })
    if(targetElement.classList.contains(activateClass)){
      document.addEventListener(wheelEventType, lockScroll , { passive: false })
      document.addEventListener(keydownEventType, lockScroll, { passive: false })
      document.addEventListener(touchEventType, lockScroll, { passive: false })
    }

    document.addEventListener('touchstart', (e :TouchEvent) => {
      lastTouchEvent = e
    }, { passive: false })
    document.addEventListener('touchend', (e :TouchEvent) => {
      lastTouchEvent = void 0
    })
  }

}

export default UWScroll;
